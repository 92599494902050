import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const NewPasswordRole = () => {
    const location = useLocation()
    const auth = useSelector(state=>state.auth)
    // console.log((auth?.passwordResetVerification === true || ( localStorage.getItem('prv') && localStorage.getItem('prv') === 'true' )));
    
    return ( 
        (auth?.passwordResetVerification === true || ( localStorage.getItem('prv') && localStorage.getItem('prv') === 'true' )) ? <Outlet /> : <Navigate to='/firstnewpassword' state={{from:location}} replace />
    );
}

export default NewPasswordRole;