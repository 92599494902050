import { Box, Button, IconButton, Modal } from "@mui/material";
import { useForm } from "react-hook-form";
import {IoCloseCircleOutline} from 'react-icons/io5'
import {FiEdit2} from 'react-icons/fi'
import {IoMdAdd} from 'react-icons/io'
import '../../../Assets/style/style.scss'
import { MdDeleteSweep } from "react-icons/md";
import { useDispatch } from "react-redux";
import { deleteCategories, editCategories, getCategories, getSingleCategory } from "../../../Components/Redux/Slice/Menu/menu";
import { useEffect, useState } from "react";
import AddCategoryModal from "./addCategoryModal";

const MenuManageCategoryModal = ({open,close,categories,activeTab,t}) => {
    const form = useForm()
    const {register,control,handleSubmit,formState,setValue,getValues} = form
    const {errors, isDirty} = formState

    const dispatch = useDispatch()

    // console.log(categories);

    const [activeField, setactiveField] = useState(null)
    const [editMode, seteditMode] = useState(false)
    const [editID, seteditID] = useState(null)
    const [deleteId, setdeleteId] = useState(null)
    const [defaultValues, setDefaultValues] = useState({});
    const [openAddCategory, setopenAddCategory] = useState(false);

    const handleAddCategory = () => {
        setopenAddCategory(true)
        close()
    };
    const handleCloseAddCategory = () => setopenAddCategory(false);

//     useEffect(() => {
//         setValue('firstName', defaultValues?.name); 
//         setValue('lastName', defaultValues?.description); 
        
//   }, [setValue])

    const editModeHandler = (index,item)=>{
        setactiveField(index)
        seteditMode(true)
        setDefaultValues(item);
        // console.log(item);
    }

    useEffect(() => {
        // console.log(editID);
        // console.log(defaultValues);
    }, [defaultValues, editID])

    const modalSubmitHandler = async (data)=>{
        // console.log(data);
        await dispatch(editCategories({editID,data})).then( (e)=>{
            if(e?.payload?.message === 'SUCCESS'){
                dispatch(getCategories())
                seteditMode(false)
                close()
            }
        } )
    }
    
    const style = {
        position: 'absolute',
        top: '45%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 3,
        p: 4,
        borderRadius : 5,
    };


    const [openModal,setopenModal] = useState(false)
    const handleOpenModal = ()=>setopenModal(true)
    const handleCloseModal = ()=>setopenModal(false)

    useEffect(() => {
        setValue('name', defaultValues?.name || '');
        setValue('nameAr', defaultValues?.nameAr || '');
        setValue('description', defaultValues?.description || '');
        setValue('descriptionAr', defaultValues?.descriptionAr || '');
      }, [defaultValues, setValue]);
    
      const handleClose = ()=>{
        close()
        seteditMode(false)
      }
    return ( 
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="menu-modal-title"
                aria-describedby="menu-modal-description"
            >
                <Box sx={style} dir={localStorage.getItem('i18nextLng') === 'ar' ? 'rtl' : 'ltr'}>
                    <div className="flex justify-between items-center">
                        <h2 className="text-[#333333] text-lg font-bold mb-5 capitalize">{t('menu.manage_category_btn')}</h2>

                        <div>
                            <Button color="error" className={`text-2xl ${localStorage.getItem('i18nextLng') === 'ar' ? '-mt-4' : '-mt-2' } `} onClick={handleClose}><IoCloseCircleOutline /></Button>
                        </div>
                    </div>
                    
                    <div className="menu-modal-form-wrapper mt-5" >
                        <ul className="max-h-[250px] overflow-hidden overflow-y-auto">
                            
                            {categories?.categories?.data?.map((item,index)=>(
                                <li key={item.id}
                                    className="w-full flex justify-between items-center capitalize pb-1 mb-3 border-b border-b-[#dedd] text-[15px]"
                                >
                                    {/* {console.log(item)} */}
                                    {activeField === index && editMode ? 
                                    
                                    <form onSubmit={handleSubmit(modalSubmitHandler)} noValidate className="flex flex-wrap gap-y-2 justify-between items-center px-1 w-full [&>div>label]:capitalize [&>div>label]:text-[#333] [&>div>label]:text-sm [&>div>label]:block [&>div>label]:mb-2 [&>div>label]:font-semibold [&>div>input]:bg-[#f3f6f9] [&>div>input]:w-full [&>div>input]:p-3 [&>div>input]:focus-visible:outline-slate-400 [&>div>input]:rounded-md [&>div>input]:text-mainColor [&>div>input]:mb-1">
                                        <div className="basis-full lg:basis-[48%]">
                                            {/* <label>category name</label> */}
                                            <input type="text" id="name"
                                                {...register('name',{
                                                    required:{
                                                        value : true,
                                                        message : t('common.required')
                                                    },
                                                    minLength : {
                                                        value : 3,
                                                        message : 'min length is 3 characters'
                                                    },
                                                    maxLength : {
                                                        value : 50,
                                                        message : 'max length is 50 characters'
                                                    },
                                                    pattern: {
                                                        value: /^(?!.*\b(deal|offer)\b)[a-zA-Z\s]*$/i,
                                                        message: 'Accept English letters only (deal/offer) words not allowed'
                                                    }
                                                })} 
                                                minLength={3}
                                                maxLength={50}
                                                // defaultValue={item?.name || ''}
                                            />

                                            {errors?.name?.message ? <p className="capitalize text-[11px] text-danger">{errors?.name?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.form.name_en')}</p>}
                                        </div>

                                        <div className="basis-full lg:basis-[48%]">
                                            {/* <label>category name</label> */}
                                            <input type="text" id="nameAr"
                                                {...register('nameAr',{
                                                    required:{
                                                        value : true,
                                                        message : t('common.required')
                                                    },
                                                    minLength : {
                                                        value : 3,
                                                        message : 'min length is 3 characters'
                                                    },
                                                    maxLength : {
                                                        value : 50,
                                                        message : 'max length is 50 characters'
                                                    },
                                                    pattern: {
                                                        value: /^[\u0621-\u064A\s&]+$/,
                                                        message: 'Only Arabic letters, spaces and & are allowed'
                                                    },
                                                })} 
                                                minLength={3}
                                                maxLength={50}
                                                // defaultValue={item?.name || ''}
                                            />

                                            {errors?.nameAr?.message ? <p className="capitalize text-[11px] text-danger">{errors?.nameAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]"> {t('menu.form.name_ar')}</p>}
                                        </div>

                                        <div className="basis-full">
                                            {/* <label>category name</label> */}
                                            <input type="text" id="description"
                                                {...register('description',{
                                                    required:{
                                                        value : true,
                                                        message : t('common.required')
                                                    },
                                                    pattern: {
                                                        value: /^[a-zA-Z0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                        message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالاحرف الانجليزيه والارقام وجميع الرموز الخاصة' : 'Only English letters, numbers, and all special characters are allowed'
                                                    },
                                                    // minLength : {
                                                    //     value : 10,
                                                    //     message : 'min length is 10 characters'
                                                    // },
                                                    // maxLength : {
                                                    //     value : 500,
                                                    //     message : 'max length is 500 characters'
                                                    // }
                                                })} 
                                                
                                                minLength={10}
                                                maxLength={500}
                                                // defaultValue={item?.description || ''}
                                            />
                                            {/* {console.log(item?.description)} */}

                                            {errors?.description?.message ? <p className="capitalize text-sm text-danger">{errors?.description?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]">{t('menu.form.desc_en')}</p>}
                                        </div>

                                        <div className="basis-full">
                                            {/* <label>category name</label> */}
                                            <input type="text" id="descriptionAr"
                                                {...register('descriptionAr',{
                                                    required:{
                                                        value : true,
                                                        message : t('common.required')
                                                    },
                                                    // minLength : {
                                                    //     value : 10,
                                                    //     message : 'min length is 10 characters'
                                                    // },
                                                    // maxLength : {
                                                    //     value : 500,
                                                    //     message : 'max length is 500 characters'
                                                    // },
                                                    pattern: {
                                                        value: /^[\u0621-\u064A0-9 !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
                                                        message: localStorage.getItem('i18nextLng') === 'ar' ? 'مسموح بالأحرف العربية والأرقام وجميع الرموز الخاصة' : 'Only Arabic letters, numbers, and all special characters are allowed'
                                                    }
                                                })} 
                                                
                                                minLength={10}
                                                maxLength={500}
                                                // defaultValue={item?.descriptionAr || ''}
                                            />
                                            {/* {console.log(item?.descriptionAr)} */}

                                            {errors?.descriptionAr?.message ? <p className="capitalize text-sm text-danger">{errors?.descriptionAr?.message}</p> : <p className="capitalize text-[11px] text-[#B5B5C3]"> {t('menu.form.desc_ar')}</p>}
                                        </div>

                                        <div className="text-right basis-full self-end mt-2">
                                            <Button type="submit" className="disabled:opacity-50 disabled:cursor-not-allowed bg-mainGreen w-full py-2 px-5 rounded-full mb-4 text-white capitalize text-sm">{t('menu.form.edit_btn')}</Button>
                                        </div>
                                    </form>
                                    
                                    : 
                                        <>
                                            <div>{localStorage.getItem('i18nextLng') === 'ar' ? item?.nameAr : item.name}</div>
                                            
                                            <div className="category-action-btns">
                                                <Button 
                                                    onClick={()=>{
                                                        handleOpenModal()
                                                        setdeleteId(item?.id)
                                                    }} 
                                                    color="error" 
                                                    sx={{borderBottom:1,p:.5,borderRadius:0}} className="text-2xl ml-3"><span><MdDeleteSweep /></span></Button>

                                                    <IconButton className="text-mainGreen text-2xl ml-2" onClick={()=>{
                                                            editModeHandler(index,item)
                                                            seteditID(item?.id)
                                                            setDefaultValues(item)
                                                        }}><FiEdit2 />
                                                    </IconButton>
                                            </div>

                                            
                                        </>
                                    }
                                </li>
                                
                            ))}
                        </ul>
                    </div>

                    {/* <div className="add-new-category-btn mt-7 text-right">
                        <Button className="bg-mainColor rounded-md capitalize text-white text-[12px] px-5 py-2" onClick={close}>close</Button>
                    </div> */}
                    <div className="mt-7 text-right text-[15px]"><Button className="bg-mainGreen text-white capitalize rounded-md" onClick={handleAddCategory}><IoMdAdd className={` ${localStorage.getItem('i18nextLng') === 'ar' ? 'ml-2' : 'mr-2'}  bg-white text-mainGreen rounded-[50%]`} /> {t('menu.add_category.title')} </Button> </div>
                </Box>
            </Modal>

            <AddCategoryModal open={openAddCategory} close={handleCloseAddCategory} t={t} />

            <>
                <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="modal-warning"
                    aria-describedby="modal-warning-delete"
                    >
                    <Box sx={style}>
                        <div className="warnning-modal-wrapper">
                            <div className="flex justify-end items-center">
                                <div>
                                    <Button color="error" className="text-2xl -mt-2" onClick={handleCloseModal}><IoCloseCircleOutline /></Button>
                                </div>
                            </div>

                            <div className="capitalize text-center w-full font-semibold text-mainRed text-lg mt-4">
                                <h2>{t('menu.warning_modal.body')}</h2>
                            </div>

                            <div className="flex gap-x-7 items-center mt-8 w-full justify-end pr-4">
                                <Button className="bg-gray-400 text-white" onClick={close}>{t('menu.warning_modal.cancel_btn')}</Button>
                                <Button className="bg-mainRed text-white" onClick={()=>dispatch(deleteCategories(deleteId)).then( ()=>dispatch(getCategories()) ).then( (e)=>{
                                    if(e?.payload?.data){
                                        if(e?.payload?.data?.length !==0){
                                            dispatch(getSingleCategory(e?.payload?.data[0]?.id)).then( ()=>{
                                                activeTab(0)
                                            } )
                                        }
                                    }
                                    handleCloseModal()
                                } )}>{t('menu.warning_modal.save_btn')}</Button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </>
        </>
    );
}
export default MenuManageCategoryModal;